import "slick-carousel";

// Disable ajax caching
$.ajaxSetup({ cache: false, traditional: true });

(function () {
    $(document).ready(function () {
        $("html").removeClass("preparation");
        $("#bog-slider").slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            autoplay: true,
            autoplaySpeed: 3500,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1217,
                    settings: {
                        slidesToShow: 3,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        arrows: false
                    }
                },
                {
                    breakpoint: 996,
                    settings: {
                        slidesToShow: 2,
                        arrows: false
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        arrows: false
                    }
                }
            ]
        });

        $(window).on('resize orientationchange', function() {
            $('#bog-slider').slick('resize');
        });
        
        $(".bog.card").on("click", function(e){
            const url = $(this).data("url");
            if (url !== undefined && url.length > 0 && e.target.id != "koeb-btn") {
                window.location = url;
            }
        });
    });
})();